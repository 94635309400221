<template>
    <div>
        <div class="pt-2 mx-8">
            <div class="collapse bg-base-100 mb-2" v-if="detailData.running.length > 0">
                <input type="checkbox" v-model="isRunning"/>
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>进行中</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.running_count }}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>创建时间</th>
                                <th>原因</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- row 1 -->
                            <tr v-for="(item, index) in detailData.running" :key="'running' + index">
                                <th>{{ index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>{{ item.created_at }}</td>
                                <td>{{ item.reason }}</td>
                                <td>
                                    <button class="btn btn-xs btn-outline btn-error" @click="handleStopDetail(item.id)">停止</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.success.length > 0">
                <input type="checkbox" v-model="isSuccess" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>元数据扫描</div>
                        <div class="badge badge-success gap-2 ml-2">
                            {{detailData.success_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>文件路径</th>
                                <th class="w-32">原因</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- row 1 -->
                            <tr v-for="(item, index) in detailData.success" :key="'mode' + index">
                                <th>{{index + 1}}</th>
                                <td>{{item.name}}</td>
                                <td>{{item.path}}</td>
                                <td>读取元数据</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.mode_success.length > 0">
                <input type="checkbox" v-model="isModeSuccess" />
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>音乐收藏</div>
                        <div class="badge badge-success gap-2 ml-2">
                            {{detailData.mode_success_count}}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>文件名</th>
                                    <th>文件路径</th>
                                    <th class="w-32">原因</th>
                                </tr>
                            </thead>
                            <tbody>
                                <!-- row 1 -->
                                <tr v-for="(item, index) in detailData.mode_success" :key="'mode' + index">
                                    <th>{{index + 1}}</th>
                                    <td>{{item.name}}</td>
                                    <td>{{item.path}}</td>
                                    <td>{{item.reason}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.fail.length > 0">
                <input type="checkbox" v-model="isFail"/>
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>异常失败</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.fail_count }}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table table-zebra">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th></th>
                                <th>文件名</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th>专辑</th>
                                <th>原因</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <!-- row 1 -->
                            <tr v-for="(item, index) in detailData.fail" :key="'fail' + index">
                                <th>{{ index + 1 }}</th>
                                <td>{{ item.name }}</td>
                                <td>{{ item.title }}</td>
                                <td>{{ item.artist }}</td>
                                <td>{{ item.album }}</td>
                                <td>{{ item.reason }}</td>
                                <td>
                                    <a class="link link-secondary" v-if="item.candidate_list.length >0"
                                       @click="handleBeixuan(item)">候选</a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'log-detail',
        props: {
            detailData: { type: Object, required: true }
        },
        data() {
            return {
                id: this.$route.params.id,
                isOpen: true,
                timer: null
            }
        },
        computed: {
            isSuccess() {
                return this.detailData.success.length > 0
            },
            isFail() {
                return this.detailData.fail.length > 0
            },
            isModeSuccess() {
                return this.detailData.mode_success.length > 0
            },
            isMissAA() {
                return this.detailData.miss_artist_album.length > 0
            },
            isMissArtist() {
                return this.detailData.miss_artist.length > 0
            },
            isMissAlbum() {
                return this.detailData.miss_album.length > 0
            },
            isMissTag() {
                return this.detailData.miss_tag.length > 0
            },
            isRunning() {
                return this.detailData.running.length > 0
            },
            isSkip() {
                return this.detailData.skip.length > 0
            }
        },
        mounted() {
            // this.handlePolling()
        },
        beforeDestroy() {
            this.setTimerNull() // 组件销毁前清空定时器
        },
        methods: {
            handleRemoveFile(path, mode) {
                this.$bkInfo({
                    title: '确认要删除文件？',
                    confirmLoading: true,
                    confirmFn: () => {
                        try {
                            this.isLoading = true
                            this.$api.Task.deleteFiles(
                                {
                                    'log_id': this.id,
                                    'paths': [path],
                                    'mode': mode
                                }).then((res) => {
                                this.isLoading = false
                                if (res.result) {
                                    this.$cwMessage('删除成功', 'success')
                                    this.$parent.fetchDetails()
                                } else {
                                    this.$cwMessage(res.message || '删除失败', 'error')
                                }
                            })
                            return true
                        } catch (e) {
                            console.warn(e)
                            return false
                        }
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>
