<template>
    <div id="container">
        <keep-alive include="library">
            <router-view />
        </keep-alive>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            }
        }
    }
</script>

<style scoped>
</style>
