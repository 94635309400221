<template>
    <div class="overflow-y-auto h-screen-header" ref="genreview">
        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <a v-for="{ value, text } in options" :key="value"
                :class="{ 'tab': true, 'tab-active': sortValue === value }">
                <router-link :to="{ 'path': '/genres/' + value }">
                    {{ text }}
                </router-link>
            </a>
        </div>
        <Tiles>
            <Tile v-for="item in sortedItems" :key="item.id"
                :to="{ name: 'genre', params: { id: item.id } }"
                :title="item.name" :image="item.image">
                <template #text>
                    <div class="text-sm">
                        <strong>{{ item.albumCount }}</strong> 专辑 •
                        <strong>{{ item.trackCount }}</strong> 歌曲
                    </div>
                </template>
                <template #play>
                    <div class="absolute top-[43%] z-10 left-[39%] play-icon" @click="playNow(item.id)">
                        <svg class="fill-primary w-12 h-12 hover:fill-accent transition-colors duration-300" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2415" width="32" height="32"><path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024zM383.232 287.616v448l384-223.104-384-224.896z" p-id="2416"></path></svg>
                    </div>
                </template>
            </Tile>
        </Tiles>
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
        <button
            v-if="curScrollTop > 700"
            @click="scrollToTop"
            class="fixed bottom-8 right-8 bg-neutral-content hover:bg-accent hover: text-accent-content text-neutral font-bold py-2 px-4 rounded-full shadow-xl transition-colors duration-300"
        >
            <svg t="1730534469934" class="icon h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2399" width="200" height="200"><path d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3c-3.8 5.3-0.1 12.7 6.5 12.7h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z" p-id="2400"></path></svg>
        </button>
    </div>
</template>
<script>
import Tiles from '@/components/library/Tiles'
import Tile from '@/components/library/Tile'

export default {
        name: 'genres',
        components: {
            Tiles, Tile
        },
        props: {
            sort: {type: String, default: null}
        },
        data() {
            return {
                loading: true,
                items: [],
                curScrollTop: 0,
                options: [
                    {text: 'A-Z排序', value: 'a-z'},
                    {text: '最多专辑', value: 'most-albums'},
                    {text: '最多歌曲', value: 'most-tracks'}
                ]
            }
        },
        computed: {
            sortedItems() {
                return this.items
            },
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        watch: {
            // sortValue: {
            //     async handler() {
            //         this.items = await this.$api.subsonic.getGenres(this.sortValue)
            //     }
            // }
        },
        mounted() {
        },
        async created() {
            this.items = await this.$api.subsonic.getGenres(this.sortValue)
            this.loading = false
        },
        activated() {
            this.$refs.genreview.addEventListener('scroll', this.listenBottomOut)
            window.genreview = this.$refs.genreview
            window.genreview.scrollTop = this.curScrollTop || 0
        },
        deactivated(){
            window.genreview.removeEventListener('scroll', this.listenBottomOut, false)
        },
        methods: {
            async playNow(id) {
                const genre = await this.$api.subsonic.getTracksByGenre(id, 100)
                return this.$store.dispatch('playTrackList', {
                    tracks: genre
                })
            },
            listenBottomOut() {
                this.curScrollTop = window.genreview.scrollTop || document.body.scrollTop
            },
            scrollToTop() {
                window.genreview.scrollTop = 0
            }
        }
    }
</script>
