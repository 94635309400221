<template>
    <div class="relative form-control w-full max-w-xs">
        <label class="input input-bordered input-sm flex items-center gap-2">
            <input
                type="text"
                v-model="selectedOption"
                class="grow"
                :placeholder="placeholder"
                @click="toggleDropdown"
                @blur="onBlur"
                @keyup.enter="handleEnter"
            />
            <svg t="1727684299243" v-show="selectedOption && !isSelecting" @click.prevent.stop="clearInput" class="fill-gray-300 h-4 w-4 cursor-pointer" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1612" width="200" height="200"><path d="M512 85.333333C276.352 85.333333 85.333333 276.352 85.333333 512s191.018667 426.666667 426.666667 426.666667 426.666667-191.018667 426.666667-426.666667S747.648 85.333333 512 85.333333zM671.061333 662.848c-19.242667 19.221333-50.410667 19.221333-69.632 0l-81.216-81.216-81.216 81.216c-19.242667 19.221333-50.410667 19.221333-69.632 0-19.221333-19.242667-19.221333-50.410667 0-69.632L450.581333 512l-81.237333-81.216c-19.221333-19.242667-19.221333-50.410667 0-69.632 19.242667-19.221333 50.410667-19.221333 69.632 0l81.216 81.216 81.216-81.216c19.242667-19.221333 50.410667-19.242667 69.632 0 19.221333 19.242667 19.221333 50.410667 0 69.632L589.824 512l81.237333 81.216C690.282667 612.458667 690.304 643.626667 671.061333 662.848z"></path></svg>
        </label>
        <div class="mt-2">
            <div v-if="selectedOption[selectedOption.length - 1] === '$'">
                <div class="absolute top-full left-0 z-10 menu p-2 shadow-lg bg-base-100 rounded-box w-full">
                    <div
                        v-for="(suggestion, index) in suggestions"
                        :key="index"
                        class="menu-item p-2 cursor-pointer hover:bg-neutral hover:text-white"
                        @click="selectSuggestion(suggestion)"
                    >
                        {{ suggestion }}
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-if="showDropdown && optionsShow.length >0" class="absolute top-full left-0 z-10 menu p-2 shadow-lg bg-base-100 rounded-lg w-full border border-solid">
                    <div
                        v-for="(item, index) in optionsShow"
                        :key="'op'+index"
                        class="menu-item p-2 cursor-pointer hover:bg-base-200 hover:rounded-lg"
                        @click="selectOption(item.id)"
                    >
                        <div class="flex justify-between">
                            <div>{{ item.name }}</div>
                            <div v-if="value === item.id">✓</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true
        },
        placeholder: {
            type: String,
            default: '请输入变量, 输入$可查看支持的变量列表'
        }
    },
    data() {
        return {
            showDropdown: false,
            suggestions: ['${artist}', '${album}'],
            isSelecting: false
        };
    },
    computed: {
        selectedOption: {
            get() {
                return this.options.find(opt => opt.id === this.value) ? this.options.find(opt => opt.id === this.value).name : this.value
            },
            set(v) {
                this.$emit('input', v);
                if(v === ''){
                    this.$emit('null', '')
                    this.showDropdown = false
                }
            }
        },
        optionsShow: {
            get() {
                // if (this.value && !this.value.startsWith('state:')) {
                //     return this.options.filter(opt => opt.name.includes(this.value))
                // } else {
                //     return this.options
                // }
                return this.options
            },
        }
    },
    beforeDestroy() {
        clearTimeout(this.blurTimeout);
    },
    methods: {
        toggleDropdown() {
            this.showDropdown = !this.showDropdown
        },
        selectOption(option) {
            this.selectedOption = option;
            this.$emit('input', option);
            this.$emit('change', option);
            this.isSelecting = true;
            this.showDropdown = false;
        },
        selectSuggestion(value) {
            this.selectedOption = this.selectedOption.substring(0, this.selectedOption.length - 1) + value
            this.$emit('input', this.selectedOption);

            this.showDropdown = false;
        },
        onBlur() {
            // 设置一个短暂的延迟
            this.blurTimeout = setTimeout(() => {
                if (this.isSelecting) {
                    this.isSelecting = false; // 重置标志
                    return; // 如果正在选择，则不关闭下拉菜单
                }
                this.showDropdown = false
            }, 150); // 150ms 是一个常用的值，可以根据实际情况调整

        },
        handleEnter() {
            this.$emit('enter', this.value);
        },
        clearInput() {
            this.showDropdown = false; // 关闭下拉菜单
            this.selectedOption = ''; // 清空输入
            this.$emit('input', ''); // 触发父组件的更新
        }
    }
};
</script>

<style scoped>
.select-box {
    position: relative;
    display: inline-block;
}

.dropdown {
    border: 1px solid #ccc;
    background-color: white;
    position: absolute;
    z-index: 1000;
    max-height: 200px;
    overflow-y: auto;
    width: 100%;
}

.dropdown.active {
    display: block;
}

.dropdown div {
    padding: 5px;
}

.dropdown div:hover {
    background-color: #f8f8f8;
}
</style>
