<template>
    <div class="bg-base-200 h-screen-header rounded-xl p-2">
        <div class="flex flex-col">
            <h1 class="mt-2 ml-2 text-2xl">
                发现音乐
            </h1>
        </div>
        <div class="body">
            <div class="shell">
                <div class="box">
                    <div class="images">
                        <img src="http://127.0.0.1:88/rest/getCoverArt/?id=alw-3714&v=1.16.0&u=admin&s=IRWf3Qqu&t=4e81bf1e6bbb7626bbf5a527276c5600&c=MusicTagWeb&size=300">
                    </div>
                    <div class="content">
                        <h2>威远故事</h2>
                        <p class="pl-2">Gai周延</p>
                    </div>
                </div>
                <div class="box">
                    <div class="images">
                        <img src="http://127.0.0.1:88/rest/getCoverArt/?id=alw-3706&v=1.16.0&u=admin&s=XHQ74xrG&t=decd976ac2c5f2f43148b5f694a9a095&c=MusicTagWeb&size=300">
                    </div>
                    <div class="content">
                        <h2>ZeenChin</h2>
                        <p>The style in the painting integrates temptation, fantasy and strangeness
                        </p>
                    </div>
                </div>
                <div class="box">
                    <div class="images">
                        <img src="http://127.0.0.1:88/rest/getCoverArt/?id=alw-3701&v=1.16.0&u=admin&s=XHQ74xrG&t=decd976ac2c5f2f43148b5f694a9a095&c=MusicTagWeb&size=300">
                    </div>
                    <div class="content">
                        <h2>ZeenChin</h2>
                        <p>The style in the painting integrates temptation, fantasy and strangeness
                        </p>
                    </div>
                </div>
                <div class="box">
                    <div class="images">
                        <img src="http://127.0.0.1:88/rest/getCoverArt/?id=alw-3677&v=1.16.0&u=admin&s=XHQ74xrG&t=decd976ac2c5f2f43148b5f694a9a095&c=MusicTagWeb&size=300">
                    </div>
                    <div class="content">
                        <h2>ZeenChin</h2>
                        <p>The style in the painting integrates temptation, fantasy and strangeness
                        </p>
                    </div>
                </div>
                <div class="box">
                    <div class="images">
                        <img src="http://127.0.0.1:88/rest/getCoverArt/?id=alw-3687&v=1.16.0&u=admin&s=XHQ74xrG&t=decd976ac2c5f2f43148b5f694a9a095&c=MusicTagWeb&size=300">
                    </div>
                    <div class="content">
                        <h2>ZeenChin</h2>
                        <p>The style in the painting integrates temptation, fantasy and strangeness
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col">
            <h1 class="mt-2 ml-2 text-2xl">
                每日推荐
            </h1>
        </div>
    </div>
</template>
<script>
import TrackList from '@/views/library/track/TrackList'

export default {
    components: {
        TrackList
    },
    computed: {
        isPlaying() {
            return this.$store.state.common.isPlaying
        },
        tracks() {
            return this.$store.state.common.queue
        },
        queueIndex() {
            return this.$store.state.common.queueIndex
        }
    },
    created() {
    },
    methods: {
        play(index) {
            if (index === this.queueIndex) {
                return this.$store.dispatch('playPause')
            }
            return this.$store.dispatch('playTrackListIndex', {index})
        },
        dragstart(id, event) {
            event.dataTransfer.setData('application/x-track-id', id)
        },
        remove(idx) {
            return this.$store.commit('removeFromQueue', idx)
        },
        clear() {
            return this.$store.commit('clearQueue')
        },
        shuffle() {
            return this.$store.commit('shuffleQueue')
        },
        formatDuration(value) {
            if (!isFinite(value)) {
                return '∞'
            }
            const minutes = Math.floor(value / 60)
            const seconds = Math.floor(value % 60)
            return (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds
        },
        async playNow(id) {
            const playlist = await this.$api.subsonic.getPlaylist(id)
            return this.$store.dispatch('playTrackList', {
                tracks: playlist.tracks
            })
        }
    }
}
</script>
<style scoped>
.body {
    display: flex;
    justify-content: start;
    align-items: center;
}

.shell {
    position: relative;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    /* 设置3d变化 */
    transform-style: preserve-3d;
    /* 添加透视效果 */
    perspective: 900px;
}

.shell .box {
    position: relative;
    width: 200px;
    height: 270px;
    transition: 0.3s;
    overflow: hidden;
    margin: 15px;
    transform: rotateY(0deg);
    transition-delay: .1s;
    border-radius: 5px;
    border: #fff 5px solid;
}

/* 当鼠标所有卡片的父元素点shell上时，
所有卡片像Y轴翻转20度 */
.shell:hover .box {
    transform: rotateY(20deg);
}

/* 当鼠标悬浮在当前卡片上时，当前卡片的翻转角度为0，
并放大一点二五倍，加个阴影 */
.shell .box:hover {
    transform: rotateY(0deg) scale(1.25);
    box-shadow: 0 25px 40px rgba(0, 0, 0, 0.7);
    z-index: 1;
}

/* 当鼠标悬浮在卡片上时，
当前卡片后面的所有卡片全部都反向翻转负20度 */
.shell .box:hover~.box {
    transform: rotateY(-20deg);
}

.shell .box .images img {
    width: 100%;
}

.shell .box .content {
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 999;
    padding: 15px;
}

.shell .box .content h2 {
    color: rgb(210, 140, 140);
    transition: 0.3s;
    font-size: 20px;
    transform: translateY(-100px);
}

.shell .box:hover .content h2 {
    transform: translateY(-15px);
}

.shell .box .content p {
    color: rgb(0, 0, 0);
    transition: 0.3s;
    font-size: 14px;
    transform: translateY(600px);
    background-color: rgba(255, 255, 255, 0.7);
}

.shell .box:hover .content p {
    transform: translateY(185px);
}
</style>
