// test.vue
<template>
    <div class="wrap">
        <input type="text" v-model="inputVal">
    </div>
</template>

<script>
export default {
    name:'test',
    data(){
        return {
            inputVal:'',
        }
    }
}
</script>
