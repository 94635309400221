<template>
    <div class="overflow-y-auto h-screen-header" ref="progressview">
        <div role="tablist" class="tabs tabs-boxed bg-base-100 mx-2 mt-2 shadow-sm rounded-xl !flex">
            <a role="tab" class="tab tab-active">刮削完整度</a>
        </div>
        <div>
            <div class="flex flex-col md:flex-row">
                <div
                    class="bg-base-100 md:w-1/2 md:h-60 shadow-xl rounded-xl m-2 flex flex-col md:flex-row md:justify-between">
                    <div class="p-8">
                    </div>
                    <img src="/static/dist/img/play_music.svg" alt="Album" class="h-64 z-1"/>
                </div>
                <div class="m-2 flex flex-col grow">
                    <div class="stats stats-vertical md:stats-horizontal shadow-xl">
                        <div class="stat">
                            <div class="stat-figure text-primary">
                                <svg t="1713434023579" class="fill-secondary" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="2454" width="32" height="32">
                                    <path
                                        d="M511.082 36.049c-263.069 0-476.33 212.903-476.33 475.534s213.261 475.534 476.33 475.534c263.073 0 476.33-212.903 476.33-475.534 0-262.63-213.255-475.534-476.33-475.534zM772.988 397.6l-306.63 313.276c-14.106 14.413-36.99 14.413-51.094 0l-166.087-169.696c-14.115-14.414-14.115-37.788 0-52.206 14.105-14.419 36.99-14.419 51.094 0l140.541 143.585 281.072-287.169c14.115-14.418 37.001-14.418 51.105 0 14.115 14.417 14.115 37.792 0 52.21z"
                                        p-id="2455"></path>
                                </svg>
                            </div>
                            <div class="stat-title">缺失歌词</div>
                            <div class="stat-value text-primary cursor-pointer">{{ detailData.static.miss_lyrics_count }}
                            </div>
                            <div class="stat-desc">包括成功失败和未匹配</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1713434236753" class="fill-primary" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="3509" width="32" height="32">
                                    <path
                                        d="M873.48 933.77c-16.22 0-29.36-14.33-29.36-32v-754c0-11.05-8.22-20-18.35-20H230.75c-16.22 0-29.36-14.33-29.36-32s13.15-32 29.36-32h598.69c40.54 0 73.4 35.82 73.4 80v758c0.01 17.67-13.14 32-29.36 32z"
                                        p-id="3510"></path>
                                    <path
                                        d="M713.71 224.22c8.82 0 16 7.18 16 16v640c0 8.82-7.18 16-16 16h-534c-8.82 0-16-7.18-16-16v-640c0-8.82 7.18-16 16-16h534m0-64h-534c-44.18 0-80 35.82-80 80v640c0 44.18 35.82 80 80 80h534c44.18 0 80-35.82 80-80v-640c0-44.18-35.82-80-80-80z"
                                        p-id="3511"></path>
                                    <path
                                        d="M648.9 397.33H242.01c-17.67 0-32 14.33-32 32s14.33 32 32 32H648.9c17.67 0 32-14.33 32-32s-14.32-32-32-32zM564.02 549.69H242.01c-17.67 0-32 14.33-32 32s14.33 32 32 32h322.01c17.67 0 32-14.33 32-32s-14.32-32-32-32z"
                                        p-id="3512"></path>
                                </svg>
                            </div>
                            <div class="stat-title">缺失封面</div>
                            <div class="stat-value text-secondary">{{ detailData.static.miss_cover_count }}</div>
                            <div class="stat-desc">总共个数</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1713434236753" class="fill-primary" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="3509" width="32" height="32">
                                    <path
                                        d="M873.48 933.77c-16.22 0-29.36-14.33-29.36-32v-754c0-11.05-8.22-20-18.35-20H230.75c-16.22 0-29.36-14.33-29.36-32s13.15-32 29.36-32h598.69c40.54 0 73.4 35.82 73.4 80v758c0.01 17.67-13.14 32-29.36 32z"
                                        p-id="3510"></path>
                                    <path
                                        d="M713.71 224.22c8.82 0 16 7.18 16 16v640c0 8.82-7.18 16-16 16h-534c-8.82 0-16-7.18-16-16v-640c0-8.82 7.18-16 16-16h534m0-64h-534c-44.18 0-80 35.82-80 80v640c0 44.18 35.82 80 80 80h534c44.18 0 80-35.82 80-80v-640c0-44.18-35.82-80-80-80z"
                                        p-id="3511"></path>
                                    <path
                                        d="M648.9 397.33H242.01c-17.67 0-32 14.33-32 32s14.33 32 32 32H648.9c17.67 0 32-14.33 32-32s-14.32-32-32-32zM564.02 549.69H242.01c-17.67 0-32 14.33-32 32s14.33 32 32 32h322.01c17.67 0 32-14.33 32-32s-14.32-32-32-32z"
                                        p-id="3512"></path>
                                </svg>
                            </div>
                            <div class="stat-title">缺失专辑</div>
                            <div class="stat-value text-secondary">{{ detailData.static.miss_album_count }}</div>
                            <div class="stat-desc">总共个数</div>
                        </div>
                    </div>
                    <div class="stats stats-vertical md:stats-horizontal shadow-xl mt-4">

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1713434348124" class="icon fill-primary" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="4497" width="32" height="32">
                                    <path
                                        d="M469.333333 640l0.384 0.384L469.333333 640z m-106.282666 0l-0.384 0.384 0.384-0.384z m48.512 106.666667a87.466667 87.466667 0 0 1-61.653334-24.874667l-179.52-173.632a67.797333 67.797333 0 0 1 0-98.24c28.032-27.157333 73.493333-27.157333 101.589334 0l139.584 134.997333 319.168-308.544c28.032-27.157333 73.493333-27.157333 101.589333 0a67.925333 67.925333 0 0 1 0 98.24L472.981333 722.069333A87.530667 87.530667 0 0 1 411.562667 746.666667z"></path>
                                </svg>
                            </div>
                            <div class="stat-title">缺失艺术家</div>
                            <div class="stat-value text-secondary cursor-pointer">
                                {{ detailData.static.miss_artist_count }}
                            </div>
                            <div class="stat-desc">成功完成并匹配上的任务</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1713434399481" class="icon fill-secondary" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="1070" width="32" height="32">
                                    <path
                                        d="M567.168 512l189.184 188.352a40.064 40.064 0 0 1 0.768 55.68 37.76 37.76 0 0 1-54.4 0.832L512 566.912l-190.72 189.952a37.76 37.76 0 0 1-54.4-0.768 40.064 40.064 0 0 1 0.768-55.68L456.832 512 267.648 323.648a40.064 40.064 0 0 1-0.768-55.68 37.76 37.76 0 0 1 54.4-0.832L512 457.088l190.72-189.952a37.76 37.76 0 0 1 54.4 0.768 40.064 40.064 0 0 1-0.768 55.68L567.168 512z"></path>
                                </svg>
                            </div>
                            <div class="stat-title">缺失流派</div>
                            <div class="stat-value text-primary cursor-pointer">{{ detailData.static.miss_genre_count }}</div>
                            <div class="stat-desc">文件异常或网络异常的任务</div>
                        </div>

                        <div class="stat">
                            <div class="stat-figure text-secondary">
                                <svg t="1713494194037" class="icon" viewBox="0 0 1024 1024" version="1.1"
                                     xmlns="http://www.w3.org/2000/svg" p-id="3344" width="32" height="32">
                                    <path d="M765.610667 518.485333H298.666667V469.333333h466.944z" fill="#000000"
                                          fill-opacity=".85" p-id="3345"></path>
                                </svg>
                            </div>
                            <div class="stat-title">缺失年份</div>
                            <div class="stat-value">{{ detailData.static.miss_year_count }}</div>
                            <div class="stat-desc">元数据匹配不正确的</div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="pt-2 mx-8">
            <div class="collapse bg-base-100 mb-2" v-if="detailData.miss_lyrics.length > 0">
                <input type="checkbox" v-model="isMissTag"/>
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失歌词</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.static.miss_lyrics_count }}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th class="!hidden md:!table-cell">专辑</th>
                                <th class="!hidden md:!table-cell">年份</th>
                                <th class="!hidden md:!table-cell">风格</th>
                                <th class="!hidden md:!table-cell">歌词</th>
                                <th class="!hidden md:!table-cell">封面</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in detailData.miss_lyrics" :key="index">
                                <th>
                                    {{ index + 1 }}
                                </th>
                                <td>
                                    <div class="truncate text-ellipsis w-24 md:w-48">{{ item.name }}</div>
                                </td>
                                <td>{{ item.artist_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.year }}</td>
                                <td class="!hidden md:!table-cell">{{ item.genre }}</td>
                                <td class="!hidden md:!table-cell">{{ item.lyrics }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_cover }}</td>
                                <td class="!hidden md:!table-cell">
                                    <button class="btn btn-active btn-sm ml-2"
                                            @click.stop="redirctEditDetail(item.path)">
                                        <svg t="1702603778129" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024"
                                             version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3152" width="64"
                                             height="64">
                                            <path
                                                d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z"></path>
                                        </svg>
                                    </button>
                                </td>
                                <td class="!table-cell md:!hidden">...</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.miss_cover.length > 0">
                <input type="checkbox" v-model="isSkip"/>
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失封面</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.static.miss_cover_count }}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th class="!hidden md:!table-cell">专辑</th>
                                <th class="!hidden md:!table-cell">年份</th>
                                <th class="!hidden md:!table-cell">风格</th>
                                <th class="!hidden md:!table-cell">歌词</th>
                                <th class="!hidden md:!table-cell">封面</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in detailData.miss_cover" :key="index">
                                <th>
                                    {{ index + 1 }}
                                </th>
                                <td>
                                    <div class="truncate text-ellipsis w-24 md:w-48">{{ item.name }}</div>
                                </td>
                                <td>{{ item.artist_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.year }}</td>
                                <td class="!hidden md:!table-cell">{{ item.genre }}</td>
                                <td class="!hidden md:!table-cell">{{ item.lyrics }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_cover }}</td>
                                <td class="!hidden md:!table-cell">
                                    <button class="btn btn-active btn-sm ml-2"
                                            @click.stop="redirctEditDetail(item.path)">
                                        <svg t="1702603778129" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024"
                                             version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3152" width="64"
                                             height="64">
                                            <path
                                                d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z"></path>
                                        </svg>
                                    </button>
                                </td>
                                <td class="!table-cell md:!hidden">...</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.miss_album.length > 0">
                <input type="checkbox" v-model="isMissAlbum"/>
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失专辑</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.static.miss_album_count }}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th class="!hidden md:!table-cell">专辑</th>
                                <th class="!hidden md:!table-cell">年份</th>
                                <th class="!hidden md:!table-cell">风格</th>
                                <th class="!hidden md:!table-cell">歌词</th>
                                <th class="!hidden md:!table-cell">封面</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in detailData.miss_album" :key="index">
                                <th>
                                    {{ index + 1 }}
                                </th>
                                <td>
                                    <div class="truncate text-ellipsis w-24 md:w-48">{{ item.name }}</div>
                                </td>
                                <td>{{ item.artist_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.year }}</td>
                                <td class="!hidden md:!table-cell">{{ item.genre }}</td>
                                <td class="!hidden md:!table-cell">{{ item.lyrics }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_cover }}</td>
                                <td class="!hidden md:!table-cell">
                                    <button class="btn btn-active btn-sm ml-2"
                                            @click.stop="redirctEditDetail(item.path)">
                                        <svg t="1702603778129" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024"
                                             version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3152" width="64"
                                             height="64">
                                            <path
                                                d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z"></path>
                                        </svg>
                                    </button>
                                </td>
                                <td class="!table-cell md:!hidden">...</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.miss_artist.length > 0">
                <input type="checkbox" v-model="isMissArtist"/>
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失艺术家</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.static.miss_artist_count }}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th class="!hidden md:!table-cell">专辑</th>
                                <th class="!hidden md:!table-cell">年份</th>
                                <th class="!hidden md:!table-cell">风格</th>
                                <th class="!hidden md:!table-cell">歌词</th>
                                <th class="!hidden md:!table-cell">封面</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in detailData.miss_artist" :key="index">
                                <th>
                                    {{ index + 1 }}
                                </th>
                                <td>
                                    <div class="truncate text-ellipsis w-24 md:w-48">{{ item.name }}</div>
                                </td>
                                <td>{{ item.artist_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.year }}</td>
                                <td class="!hidden md:!table-cell">{{ item.genre }}</td>
                                <td class="!hidden md:!table-cell">{{ item.lyrics }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_cover }}</td>
                                <td class="!hidden md:!table-cell">
                                    <button class="btn btn-active btn-sm ml-2"
                                            @click.stop="redirctEditDetail(item.path)">
                                        <svg t="1702603778129" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024"
                                             version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3152" width="64"
                                             height="64">
                                            <path
                                                d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z"></path>
                                        </svg>
                                    </button>
                                </td>
                                <td class="!table-cell md:!hidden">...</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.miss_genre.length > 0">
                <input type="checkbox" v-model="isMissAA"/>
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失流派</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.static.miss_genre_count }}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th class="!hidden md:!table-cell">专辑</th>
                                <th class="!hidden md:!table-cell">年份</th>
                                <th class="!hidden md:!table-cell">风格</th>
                                <th class="!hidden md:!table-cell">歌词</th>
                                <th class="!hidden md:!table-cell">封面</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in detailData.miss_genre" :key="index">
                                <th>
                                    {{ index + 1 }}
                                </th>
                                <td>
                                    <div class="truncate text-ellipsis w-24 md:w-48">{{ item.name }}</div>
                                </td>
                                <td>{{ item.artist_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.year }}</td>
                                <td class="!hidden md:!table-cell">{{ item.genre }}</td>
                                <td class="!hidden md:!table-cell">{{ item.lyrics }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_cover }}</td>
                                <td class="!hidden md:!table-cell">
                                    <button class="btn btn-active btn-sm ml-2"
                                            @click.stop="redirctEditDetail(item.path)">
                                        <svg t="1702603778129" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024"
                                             version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3152" width="64"
                                             height="64">
                                            <path
                                                d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z"></path>
                                        </svg>
                                    </button>
                                </td>
                                <td class="!table-cell md:!hidden">...</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            <div class="collapse bg-base-100 mb-2" v-if="detailData.miss_year.length > 0">
                <input type="checkbox" v-model="isRunning"/>
                <div class="collapse-title text-xl font-medium">
                    <div class="flex items-center">
                        <div>缺失年份</div>
                        <div class="badge badge-error gap-2 ml-2">
                            {{ detailData.static.miss_year_count }}
                        </div>
                    </div>
                </div>
                <div class="collapse-content">
                    <div class="overflow-x-auto">
                        <table class="table">
                            <!-- head -->
                            <thead>
                            <tr>
                                <th>#</th>
                                <th>标题</th>
                                <th>艺术家</th>
                                <th class="!hidden md:!table-cell">专辑</th>
                                <th class="!hidden md:!table-cell">年份</th>
                                <th class="!hidden md:!table-cell">风格</th>
                                <th class="!hidden md:!table-cell">歌词</th>
                                <th class="!hidden md:!table-cell">封面</th>
                                <th>操作</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item, index) in detailData.miss_year" :key="index">
                                <th>
                                    {{ index + 1 }}
                                </th>
                                <td>
                                    <div class="truncate text-ellipsis w-24 md:w-48">{{ item.name }}</div>
                                </td>
                                <td>{{ item.artist_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_name }}</td>
                                <td class="!hidden md:!table-cell">{{ item.year }}</td>
                                <td class="!hidden md:!table-cell">{{ item.genre }}</td>
                                <td class="!hidden md:!table-cell">{{ item.lyrics }}</td>
                                <td class="!hidden md:!table-cell">{{ item.album_cover }}</td>
                                <td class="!hidden md:!table-cell">
                                    <button class="btn btn-active btn-sm ml-2"
                                            @click.stop="redirctEditDetail(item.path)">
                                        <svg t="1702603778129" class="h-5 w-5 fill-current" viewBox="0 0 1024 1024"
                                             version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3152" width="64"
                                             height="64">
                                            <path
                                                d="M862.709333 116.042667a32 32 0 1 1 45.248 45.248L455.445333 613.813333a32 32 0 1 1-45.258666-45.258666L862.709333 116.053333zM853.333333 448a32 32 0 0 1 64 0v352c0 64.8-52.533333 117.333333-117.333333 117.333333H224c-64.8 0-117.333333-52.533333-117.333333-117.333333V224c0-64.8 52.533333-117.333333 117.333333-117.333333h341.333333a32 32 0 0 1 0 64H224a53.333333 53.333333 0 0 0-53.333333 53.333333v576a53.333333 53.333333 0 0 0 53.333333 53.333333h576a53.333333 53.333333 0 0 0 53.333333-53.333333V448z"></path>
                                        </svg>
                                    </button>
                                </td>
                                <td class="!table-cell md:!hidden">...</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </div>
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
    </div>
</template>

<script>
export default {
    name: "progressRate",
    data() {
        return {
            sortValue: 'miss-album',
            loading: false,
            options: [
                {text: '缺失专辑', value: 'miss-album'},
                {text: '缺失艺术家', value: 'miss-artist'},
                {text: '缺失风格', value: 'miss-genre'},
                {text: '缺失年份', value: 'miss-year'},
                {text: '缺失歌词', value: 'miss-lyrics'},
                {text: '缺失封面', value: 'miss-cover'}
            ],
            tracks: [],
            detailData: {
                'static': {},
                'miss_album': [],
                'miss_year': [],
                'miss_artist': [],
                'miss_genre': [],
                'miss_lyrics': [],
                'miss_cover': []
            }
        }
    },
    computed: {
        isSuccess: {
            get() {
                return this.detailData.success.length > 0
            },
            set() {
            }
        },
        isFail: {
            get() {
                return this.detailData.fail.length > 0
            },
            set() {
            }
        },
        isModeSuccess: {
            get() {
                return this.detailData.mode_success.length > 0
            },
            set() {
            }
        },
        isMissAA() {
            return this.detailData.miss_genre.length > 0
        },
        isMissArtist() {
            return this.detailData.miss_artist.length > 0
        },
        isMissAlbum() {
            return this.detailData.miss_album.length > 0
        },
        isMissTag() {
            return this.detailData.miss_lyrics.length > 0
        },
        isRunning() {
            return this.detailData.miss_year.length > 0
        },
        isSkip() {
            return this.detailData.miss_cover.length > 0
        }
    },
    created() {
        this.fetchProcessStatic()
        this.fetchProgressRate("miss_album")
        this.fetchProgressRate("miss_artist")
        this.fetchProgressRate("miss_genre")
        this.fetchProgressRate("miss_year")
        this.fetchProgressRate("miss_lyrics")
        this.fetchProgressRate("miss_cover")
    },
    methods: {
        fetchProgressRate(mode) {
            this.loading = true
            this.$api.Task.getProgressRate({'mode': mode, page: 1, page_size: 50}).then(res => {
                this.loading = false
                if (res.result) {
                    this.detailData[mode] = res.data.items
                    console.log(this.detailData)
                }
            })
        },
        fetchProcessStatic() {
            this.$api.Task.getProgressStatic().then(res => {
                if (res.result) {
                    this.detailData.static = res.data
                }
            })
        },
        redirctEditDetail(path) {
            if (this.$route.name !== 'home') {
                this.$router.push({name: 'home'})
            }
            this.$store.commit('setFullPath', path)
        }
    }
}
</script>

<style scoped>

</style>
