const getters = {
    getUserRole: state => state.common.userRole,
    getUsername: state => state.common.username,
    geFullPath: state => state.common.fullPath,
    getCheckedIds: state => state.common.checkedIds,
    getCheckedData: state => state.common.checkedData,
    isChecked: state => state.common.checkedIds.length > 0,
    getHasMsg: state => state.common.hasMsg,
    currentTime: state => state.common.currentTime,
    repeat: state => state.common.repeat,
    shuffle: state => state.common.shuffle,
    track(state) {
        if (state.common.queueIndex !== -1) {
            return state.common.queue[state.common.queueIndex]
        }
        return null
    },
    trackId(state, getters) {
        return getters.track ? getters.track.id : -1
    },
    progress(state) {
        if (state.common.currentTime > -1 && state.common.duration > 0) {
            return state.common.currentTime / state.common.duration
        }
        return 0
    },
    hasNext(state) {
        return state.common.queueIndex < state.common.queue.length - 1
    },
    hasPrevious(state) {
        return state.common.queueIndex > 0
    },
    getSearchTerm: state => state.common.searchTerm,
}
export default getters
