<template>
    <div>
        <Tiles square :small="small" :allow-h-scroll="allowHScroll">
            <Tile
                v-for="item in items" :key="item.id"
                :image="item.image"
                :to="{ name: 'album', params: { id: item.id } }"
                :title="item.name"
                :draggable="true" @dragstart="dragstart(item.id, $event)"
            >
                <template #text>
                    <router-link :to="{ name: 'artist', params: { id: item.artistId } }">
                        <div class="text-sm">{{ item.artist }}</div>
                    </router-link>
                </template>
                <template #play>
                    <div class="absolute top-[43%] z-10 left-[39%] play-icon" @click="playNow(item.id)">
                        <svg class="fill-primary w-12 h-12 hover:fill-accent transition-colors duration-300" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2415" width="32" height="32"><path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024zM383.232 287.616v448l384-223.104-384-224.896z" p-id="2416"></path></svg>
                    </div>
                </template>
            </Tile>
        </Tiles>
    </div>
</template>
<script>
    import Tiles from '@/components/library/Tiles'
    import Tile from '@/components/library/Tile'

    export default {
        components: {
            Tiles, Tile
        },
        props: {
            items: { type: Array, required: true },
            allowHScroll: { type: Boolean, default: false },
            small: { type: Boolean, default: false }
        },
        data() {
            return {
                favourites: []
            }
        },
        methods: {
            async playNow(id) {
                const album = await this.$api.subsonic.getAlbumDetails(id)
                return this.$store.dispatch('playTrackList', {
                    tracks: album.tracks
                })
            },
            async playNext(id) {
                const album = await this.$api.getAlbumDetails(id)
                return this.$store.dispatch('player/setNextInQueue', album.tracks)
            },
            async playLater(id) {
                const album = await this.$api.getAlbumDetails(id)
                return this.$store.dispatch('player/addToQueue', album.tracks)
            },
            toggleFavourite(id) {
                return this.favouriteStore.toggle('album', id)
            },
            dragstart(id, event) {
                event.dataTransfer.setData('application/x-album-id', id)
            }
        }
    }
</script>
<style>
.play-icon {
    display: none;
    cursor: pointer;
}
.tile-img:hover .play-icon {
    display: block;
}
</style>
