<template>
    <div class="h-screen-header overflow-y-auto" ref="genredetailview">

        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <div class="flex justify-between w-full">
                <div>
                    <a v-for="{ value, text } in options" :key="value"
                       :class="{ 'tab': true, 'tab-active': section === value }">
                        <div @click="handleChangeTab(value)">
                            {{ text }}
                        </div>
                    </a>
                </div>
                <div>
                    <button class="btn btn-ghost btn-active btn-sm mr-1" @click="handleEdit(id)">
                        EDIT
                    </button>
                    <button class="btn btn-neutral btn-active btn-sm" onclick="genre_delete.showModal()">
                        DELETE
                    </button>
                </div>
            </div>
        </div>
        <div class="flex justify-between mb-1">
            <div class="text-2xl">: {{ id }}</div>
        </div>
        <template v-if="section === 'tracks'">
            <TrackList :tracks="tracks" :index-field="'index'" />
        </template>
        <template v-else>
            <AlbumList :items="albums" />
        </template>
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>

        <div class="drawer drawer-end w-16 ml-2">
            <input id="edit-gr" type="checkbox" class="drawer-toggle" v-model="idEditGr" />
            <div class="drawer-content">
            </div>
            <div class="drawer-side">
                <label for="edit-gr" aria-label="close sidebar" class="drawer-overlay"></label>
                <div class="p-4 w-80 min-h-full bg-base-200 text-base-content">
                    <div class="font-medium text-xl mb-4">编辑流派/风格信息</div>

                    <div class="mb-1">风格名称:</div>
                    <div>
                        <input type="text"
                               v-model="id"
                               disabled
                               class="input input-bordered input-sm w-full max-w-xs" />
                    </div>
                    <div class="mb-1 mt-1">风格封面:</div>
                    <div>
                        <bk-upload
                            :files="files1"
                            :theme="'picture'"
                            :multiple="false"
                            :with-credentials="true"
                            :header="uploadHeader"
                            :handle-res-code="handleRes"
                            :size="{ maxFileSize: 5, maxImgSize: 20 }"
                            :url="uploadUrl"
                            name="upload_file"
                        ></bk-upload>
                    </div>
                    <button class="btn btn-neutral btn-sm btn-wide mt-2" @click="handleSave">保存</button>
                    <div class="mt-4">封面推荐:</div>
                    <div v-for="(item2, index) in albums" :key="index">
                        <div class="flex justify-center items-center mt-1 mb-2">
                            <bk-image fit="contain" :src="item2.image"
                                      class="mask mask-squircle w-16 h-16 cursor-pointer"
                                      @click="handleCopy('genre_img', item2.image)">
                            </bk-image>
                            <div class="flex-1 ml-2">
                                <div>{{item2.name}}</div>
                            </div>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>
        </div>
        <dialog id="genre_delete" class="modal modal-bottom sm:modal-middle">
            <div class="modal-box">
                <h3 class="text-lg font-bold">删除风格</h3>
                <p class="py-4">从音乐收藏中删除此风格，但不会删除源文件。</p>
                <div class="modal-action">
                    <form method="dialog">
                        <!-- if there is a button in form, it will close the modal -->
                        <button class="btn btn-neutral btn-sm mr-2" @click="handleDelete">确认</button>
                        <button class="btn btn-sm">取消</button>
                    </form>
                </div>
            </div>
        </dialog>
        <button
            v-if="curScrollTop > 700"
            @click="scrollToTop"
            class="fixed bottom-8 right-8 bg-neutral-content hover:bg-accent hover: text-accent-content text-neutral font-bold py-2 px-4 rounded-full shadow-xl transition-colors duration-300"
        >
            <svg t="1730534469934" class="icon h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2399" width="200" height="200"><path d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3c-3.8 5.3-0.1 12.7 6.5 12.7h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z" p-id="2400"></path></svg>
        </button>
    </div>
</template>
<script>
    import AlbumList from '@/views/library/album/AlbumList.vue'
    import TrackList from '@/views/library/track/TrackList.vue'

    export default {
        name: 'genre',
        components: {
            AlbumList,
            TrackList
        },
        props: {
        },
        data() {
            return {
                tracks: [],
                albums: [],
                id: this.$route.params.id,
                section: 'albums',
                loading: false,
                offset: 0,
                hasMore: true,
                loading2: false,
                offset2: 0,
                hasMore2: true,
                curScrollTop: 0,
                options: [
                    {text: '专辑', value: 'albums'},
                    {text: '歌曲', value: 'tracks'}
                ],
                FormData: {},
                idEditGr: false,
                uploadHeader: [
                    {name: 'X-CSRFToken', value: this.getCookie('django_vue_cli_csrftoken')},
                    {name: 'AUTHORIZATION', value: this.getCookie('AUTHORIZATION')}
                ],
                files1: [],
                uploadUrl: '/api/upload_image/',
                artistsData: []
            }
        },
        mounted() {
        },
        async created() {
            await this.loadMore()
        },
        activated() {
            // 事件监听
            this.$refs.genredetailview.addEventListener('scroll', this.listenBottomOut)
            window.genredetailview = this.$refs.genredetailview
            window.genredetailview.scrollTop = this.curScrollTop || 0
        },
        deactivated(){
            window.genredetailview.removeEventListener('scroll', this.listenBottomOut, false)

        },
        destroyed() {
        },
        methods: {
            async loadAlbums(offset) {
                this.albums = await this.$api.subsonic.getAlbumsByGenre(this.id, 50, offset)
            },
            async loadTracks(offset) {
                this.tracks = await this.$api.subsonic.getTracksByGenre(this.id, 50, offset)
            },
            handleChangeTab(value) {
                this.section = value
                this.loadMore()
            },
            handleEdit() {
                this.idEditGr = true
            },
            handleDelete() {
                this.$api.Task.deleteGenre({ 'id': this.id }).then((res) => {
                    if (res.result) {
                        this.$cwMessage('删除成功', 'success')
                        this.$router.push({path: '/genres/a-z/'})
                    } else {
                        this.$cwMessage('删除失败', 'error')
                    }
                })
            },
            loadMore() {
                if (this.section === 'tracks') {
                    this.loading2 = true
                    return this.$api.subsonic.getTracksByGenre(this.id, 50, this.offset2).then(tracks => {
                        this.tracks.push(...tracks)
                        this.offset2 += tracks.length
                        this.hasMore2 = tracks.length > 0
                        this.loading2 = false
                    })
                } else {
                    this.loading = true
                    return this.$api.subsonic.getAlbumsByGenre(this.id, 50, this.offset).then(albums => {
                        this.albums.push(...albums)
                        this.offset += albums.length
                        this.hasMore = albums.length > 0
                        this.loading = false
                    })
                }
            },
            listenBottomOut() {
                let hasMore
                let loading
                if (this.section === 'albums') {
                    hasMore = this.hasMore
                    loading = this.loading
                } else {
                    hasMore = this.hasMore2
                    loading = this.loading2
                }
                if (hasMore && !loading) {
                    const scrollTop = window.genredetailview.scrollTop || document.body.scrollTop
                    const scrollHeight = window.genredetailview.scrollHeight || document.body.scrollHeight
                    const clientHeight = window.genredetailview.clientHeight || document.body.clientHeight
                    this.curScrollTop = scrollTop
                    console.log(scrollTop, scrollHeight, clientHeight)
                    if (scrollTop + clientHeight >= scrollHeight - 50) {
                        this.loadMore()
                    }
                }
            },
            // 渲染图片
            handleRes(response) {
                if (response.result) {
                    this.FormData.genre_img = response.data
                    return true
                } else {
                    return false
                }
            },
            handleSave() {
                this.$api.Task.postGenre({
                    id: this.id,
                    genre_img: this.FormData.genre_img,
                }).then((res) => {
                    if (res.result) {
                        this.$cwMessage('修改成功', 'success')
                    } else {
                        this.$cwMessage('修改失败', 'error')
                    }
                })
            },
            handleCopy(k, v) {
                if (k === 'genre_img') {
                    this.files1 = [
                        {
                            name: 'cover.png',
                            status: 'done',
                            url: v
                        }
                    ]
                    this.FormData.genre_img = v
                } else {
                    this.FormData[k] += ',' + v
                }
            },
            scrollToTop() {
                window.genredetailview.scrollTop = 0
            }
        }
    }
</script>
