<template>
    <div class="overflow-y-auto h-screen-header" ref="trackview">
        <div class="tabs tabs-boxed mb-2 bg-base-100 !flex">
            <a v-for="{ value, text } in options" :key="value"
                :class="{ 'tab': true, 'tab-active': sortValue === value }">
                <router-link :to="{ 'path': '/tracks/' + value }">
                    {{ text }}
                </router-link>
            </a>
        </div>
        <TrackList :tracks="albums" :index-field="'index'" />
        <div class="flex justify-center" v-if="loading">
            <span class="loading loading-infinity loading-lg"></span>
        </div>
        <button
            v-if="curScrollTop > 700"
            @click="scrollToTop"
            class="fixed bottom-8 right-8 bg-neutral-content hover:bg-accent hover: text-accent-content text-neutral font-bold py-2 px-4 rounded-full shadow-xl transition-colors duration-300"
        >
            <svg t="1730534469934" class="icon h-5 w-5 fill-current" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2399" width="200" height="200"><path d="M890.5 755.3L537.9 269.2c-12.8-17.6-39-17.6-51.7 0L133.5 755.3c-3.8 5.3-0.1 12.7 6.5 12.7h75c5.1 0 9.9-2.5 12.9-6.6L512 369.8l284.1 391.6c3 4.1 7.8 6.6 12.9 6.6h75c6.5 0 10.3-7.4 6.5-12.7z" p-id="2400"></path></svg>
        </button>
    </div>
</template>
<script>
    import TrackList from './TrackList'
    import {mapGetters} from "vuex";
    export default {
        name: 'tracks',
        components: {
            TrackList
        },
        props: {},
        data() {
            return {
                albums: [],
                loading: false,
                offset: 0,
                hasMore: true,
                curScrollTop: 0,
                watchSearchTerm: null,
                searchTimeout: null
            }
        },
        computed: {
            ...mapGetters(['getSearchTerm']),
            options() {
                return [
                    {text: '最近添加', value: 'recently-added'},
                    {text: '最近播放', value: 'recently-played'},
                    {text: '最多播放', value: 'most-played'},
                    {text: 'A-Z排序', value: 'a-z'},
                    {text: '随机播放', value: 'random'}
                ]
            },
            sortValue: {
                get() {
                    return this.$route.params.sort
                }
            }
        },
        watch: {
            // sortValue: {
            //     handler() {
            //         this.albums = []
            //         this.offset = 0
            //         this.hasMore = true
            //         this.loadMore()
            //     }
            // },
            // getSearchTerm: {
            //     handler() {
            //         this.offset = 0
            //         this.hasMore = true
            //         this.loadSearch()
            //     }
            // }
        },
        mounted() {
        },
        destroyed() {
        },
        created() {
            this.loadMore()
            this.watchSearchTerm = this.$watch('getSearchTerm', this.loadSearchTimeout);
        },
        activated() {
            // 事件监听
            this.$refs.trackview.addEventListener('scroll', this.listenBottomOut)
            window.trackview = this.$refs.trackview
            window.trackview.scrollTop = this.curScrollTop || 0
            this.startWatching()
        },
        deactivated(){
            // 离开页面取消监听
            window.trackview.removeEventListener('scroll', this.listenBottomOut, false)
            this.stopWatching()
        },
        methods: {
            startWatching() {
                console.log('startWatching')
                if (!this.watchSearchTerm) {
                    console.log('watchSearchTerm')
                    this.watchSearchTerm = this.$watch('getSearchTerm', this.loadSearchTimeout);
                }
            },
            stopWatching() {
                console.log('stopWatching')
                if (this.watchSearchTerm) {
                    this.watchSearchTerm()
                    this.watchSearchTerm = null
                }
            },
            loadMore() {
                this.loading = true
                return this.$api.subsonic.getTracksByGenre(this.sortValue, 50, this.offset).then(albums => {
                    this.albums.push(...albums)
                    this.offset += albums.length
                    this.hasMore = albums.length > 0
                    this.loading = false
                })
            },
            loadSearch() {
                if (this.getSearchTerm === '') {
                    this.offset = 0
                    this.albums = []
                    this.hasMore = true
                    this.loadMore()
                    return
                }
                this.loading = true
                return this.$api.subsonic.searchTracksByGenre('search',this.getSearchTerm, 30, this.offset).then(albums => {
                    this.albums = albums
                    this.offset += albums.length
                    this.hasMore = albums.length > 0
                    this.loading = false
                }).catch((err)=> {
                    if (err.message === 'Wrong username or password.'){
                        window.location.reload()
                    } else {
                        this.$cwMessage(err.message, 'error')
                    }
                })
            },
            loadSearchTimeout() {
                if (this.searchTimeout) {
                    clearTimeout(this.searchTimeout)
                }
                this.searchTimeout = setTimeout(() => {
                    this.searchTimeout = null
                    this.loadSearch()
                }, 500)
            },
            listenBottomOut() {
                if (this.hasMore && !this.loading) {
                    const scrollTop = window.trackview.scrollTop || document.body.scrollTop
                    const scrollHeight = window.trackview.scrollHeight || document.body.scrollHeight
                    const clientHeight = window.trackview.clientHeight || document.body.clientHeight
                    this.curScrollTop = scrollTop
                    if (scrollTop + clientHeight >= scrollHeight - 50) {
                        this.loadMore()
                    }
                }
            },
            scrollToTop() {
                window.trackview.scrollTop = 0
            }
        }
    }
</script>
