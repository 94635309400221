import {AudioController} from '../../views/library/player/audio'
import {shuffle, trackListEquals, shuffled} from '../../common/util'

const mediaSession = navigator.mediaSession
const audio = new AudioController()
function persistQueue(state) {
    localStorage.setItem('queue', JSON.stringify(state.queue))
    localStorage.setItem('queueIndex', state.queueIndex.toString())
}

const common = {
    state: {
        // fullPath: '/Users/macbookair/Music/my_music/music', // todo test
        fullPath: '/app/media',
        checkedIds: [],
        checkedData: [],
        defaultTableHeight: 800,
        userRole: '',
        username: '',
        isPlaying: false,
        queue: [],
        queueIndex: -1,
        currentTime: 0,
        duration: 0,
        repeat: localStorage.getItem('player.repeat') !== 'false',
        shuffle: localStorage.getItem('player.shuffle') === 'true',
        hasMsg: false,
        searchTerm: ''
    },
    mutations: {
        setDefaultTableHeight: (state, val) => {
            state.defaultTableHeight = val
        },
        setUserRole: (state, val) => {
            state.userRole = val
        },
        setUsername: (state, val) => {
            state.username = val
        },
        setFullPath: (state, val) => {
            state.fullPath = val
        },
        setHasMsg: (state, val) => {
            state.hasMsg = val
        },
        setPlaying(state) {
            state.isPlaying = true
            if (mediaSession) {
                mediaSession.playbackState = 'playing'
            }
        },
        toggleRepeat(state) {
            state.repeat = !state.repeat
            localStorage.setItem('player.repeat', state.repeat)
        },
        toggleShuffle(state) {
            state.shuffle = !state.shuffle
            localStorage.setItem('player.shuffle', state.shuffle)
        },
        setPaused(state) {
            state.isPlaying = false
            if (mediaSession) {
                mediaSession.playbackState = 'paused'
            }
        },
        setCurrentTime(state, value) {
            state.currentTime = value
        },
        setDuration(state, value) {
            if (isFinite(value)) {
                state.duration = value
            }
        },
        setStreamTitle(state, value) {
            state.streamTitle = value
            const metaData = mediaSession && mediaSession.metadata ? mediaSession.metadata : undefined
            if (value && metaData) {
                mediaSession.metadata.title = value
            }
        },
        setQueue(state, queue) {
            state.queue = queue
            state.queueIndex = -1
            persistQueue(state)
        },
        setQueueIndex(state, index) {
            if (state.queue.length === 0) {
                return
            }
            index = Math.max(0, index)
            index = index < state.queue.length ? index : 0
            state.queueIndex = index
            persistQueue(state)
            state.scrobbled = false
            const track = state.queue[index]
            state.duration = track.duration
            const next = (index + 1) % state.queue.length
            console.log('next', next)
            audio.setBuffer(state.queue[next].url)
            if (mediaSession) {
                mediaSession.metadata = new window.MediaMetadata({
                    title: track.title,
                    artist: track.artist,
                    album: track.album,
                    artwork: track.image ? [{ src: track.image, sizes: '300x300' }] : undefined
                })
            }
        },
        setVolume(state, value) {
            state.volume = value
            localStorage.setItem('player.volume', String(value))
        },
        setCheckedIds(state, value) {
            state.checkedIds = value
        },
        setCheckedData(state, value) {
            state.checkedData = value
        },
        clearQueue(state) {
            if (state.queueIndex >= 0) {
                state.queue = [state.queue[state.queueIndex]]
                state.queueIndex = 0
                persistQueue(state)
            }
        },
        shuffleQueue(state) {
            if (state.queue.length > 0) {
                state.queue = shuffled(state.queue, state.queueIndex)
                state.queueIndex = 0
                persistQueue(state)
            }
        },
        setSearchTerm(state, term) {
            state.searchTerm = term;
        }
    },
    actions: {
        async playPause({ state, dispatch }) {
            return state.isPlaying ? dispatch('pause') : dispatch('resume')
        },
        async resume({ commit }) {
            commit('setPlaying')
            await audio.resume()
        },
        setVolume({ commit }, value) {
            audio.setVolume(value)
            commit('setVolume', value)
        },
        async pause({ commit }) {
            audio.pause()
            commit('setPaused')
        },
        toggleRepeat({ commit }) {
            commit('toggleRepeat')
        },
        toggleShuffle({ commit }) {
            commit('toggleShuffle')
        },
        seek({ state }, value) {
            if (isFinite(state.duration)) {
                audio.seek(state.duration * value)
            }
        },
        async next({ commit, state, getters }) {
            commit('setQueueIndex', state.queueIndex + 1)
            commit('setPlaying')
            await audio.changeTrack(getters.track)
        },
        async previous({ commit, state, getters }) {
            commit('setQueueIndex', audio.currentTime() > 3 ? state.queueIndex : state.queueIndex - 1)
            commit('setPlaying')
            await audio.changeTrack(getters.track)
        },
        async resetQueue({ commit, getters }) {
            commit('setQueueIndex', 0)
            commit('setPaused')
            await audio.changeTrack({ ...getters.track, paused: true })
        },
        async playTrackList({ commit, state, getters }, { tracks, index }) {
            if (index === null) {
                index = state.shuffle ? Math.floor(Math.random() * tracks.length) : 0
            }
            if (state.shuffle) {
                tracks = [...tracks]
                shuffle(tracks, index)
                index = 0
            }
            if (!trackListEquals(state.queue, tracks)) {
                commit('setQueue', tracks)
            }
            commit('setQueueIndex', index)
            commit('setPlaying')
            await audio.changeTrack(getters.track)
        }
    }
}

export default common
