<template>
    <div>
        <div class="overflow-x-auto">
            <table class="table">
                <!-- head -->
                <thead>
                    <tr>
                        <th>封面</th>
                        <th>艺术家</th>
                        <th>专辑数</th>
                        <th>收藏</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in items" :key="index" class="hover:bg-base-300">
                        <td class="w-16 truncate">
                            <div class="flex items-center gap-3">
                                <div class="avatar">
                                    <div class="mask mask-squircle w-12 h-12 tile-img">
                                        <img :src="item.image" v-if="item.image"
                                            alt="Avatar Tailwind CSS Component" />
                                        <img v-else src="/static/dist/img/album_null.jpg">
                                        <div class="absolute top-[30%] z-10 left-[25%] play-icon" @click.stop="playNow(item.id)">
                                            <svg class="fill-primary w-6 h-6 hover:fill-accent transition-colors duration-300" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2415" width="32" height="32"><path d="M512 1024A512 512 0 1 1 512 0a512 512 0 0 1 0 1024zM383.232 287.616v448l384-223.104-384-224.896z" p-id="2416"></path></svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </td>
                        <td class="w-72 cursor-pointer" @click="redirctDetail(item.id)">{{item.name}}</td>
                        <td>{{item.albumCount}}</td>
                        <td>
                            <button class="btn btn-active btn-sm ml-2" @click.stop="addFavouriteArtist(item)">
                                <svg xmlns="http://www.w3.org/2000/svg" :class="['h-6', 'w-6', item.favourite ? 'fill-current' : '']" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z" /></svg>
                            </button>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>
</template>
<script>

    export default {
        components: {
        },
        props: {
            items: { type: Array, required: true }
        },
        created() {
            console.log(this.items)
        },
        methods: {
            async playNow(id) {
                const tracks = await this.$api.subsonic.getTracksByArtist(id)
                return this.$store.dispatch('playTrackList', {
                    tracks: tracks
                })
            },
            redirctDetail(id) {
                this.$router.push({ name: 'artist', params: { id: id } })
            },
            addFavouriteArtist(item) {
                if (this.isFavourite) {
                    this.$api.subsonic.removeFavourite(item.id, 'artist').then((res) => {
                        item.favourite = false
                    })
                } else {
                    this.$api.subsonic.addFavourite(item.id, 'artist').then((res) => {
                        item.favourite = true
                    })
                }
            },
        }
    }
</script>
<style scoped>
.play-icon {
    display: none;
    cursor: pointer;
}

.tile-img:hover .play-icon {
    display: block;
}
</style>
