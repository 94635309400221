import {GET, POST, PUT, DELETE, reUrl} from '../../axiosconfig/axiosconfig'

export default {
    login: function (params) {
        return POST(reUrl + '/api/token/', params)
    },
    loginInfo: function (params) {
        return GET(reUrl + '/user/info/', params)
    },
    activeCode: function (params) {
        return POST(reUrl + '/user/active/', params)
    },
    logout: function (params) {
        return POST(reUrl + '/logout/', params)
    },
    fileList: function (params) {
        return POST(reUrl + '/api/file_list/', params)
    },
    fileID3List: function (params) {
        return POST(reUrl + '/api/file_id3_list/', params)
    },
    musicId3: function (params) {
        return POST(reUrl + '/api/music_id3/', params)
    },
    updateId3: function (params) {
        return POST(reUrl + '/api/update_id3/', params)
    },
    batchUpdateId3: function (params) {
        return POST(reUrl + '/api/batch_update_id3/', params)
    },
    importMusicIn: function (params) {
        return POST(reUrl + '/api/import_music/', params)
    },
    batchAutoUpdateId3: function (params) {
        return POST(reUrl + '/api/batch_auto_update_id3/', params)
    },
    tidyFolder: function (params) {
        return POST(reUrl + '/api/tidy_folder/', params)
    },
    removeNullFolder: function (params) {
        return POST(reUrl + '/api/remove_null_folder/', params)
    },
    deleteFiles: function (params) {
        return POST(reUrl + '/api/delete_files/', params)
    },
    fetchId3Title: function (params) {
        return POST(reUrl + '/api/fetch_id3_by_title/', params)
    },
    fetchArtists: function (params) {
        return POST(reUrl + '/api/fetch_artist_by_keyword/', params)
    },
    postArtist: function (params) {
        const id = params.id
        return PUT(reUrl + `/music/artist/${id}/`, params)
    },
    deleteArtist: function (params) {
        const id = params.id
        return DELETE(reUrl + `/music/artist/${id}/`, params)
    },
    postAlbum: function (params) {
        const id = params.id
        return PUT(reUrl + `/music/album/${id}/`, params)
    },
    deleteAlbum: function (params) {
        const id = params.id
        return DELETE(reUrl + `/music/album/${id}/`, params)
    },
    deleteGenre: function (params) {
        const id = params.id
        return DELETE(reUrl + `/music/genre/${id}/`, params)
    },
    postGenre: function (params) {
        const id = params.id
        return PUT(reUrl + `/music/genre/${id}/`, params)
    },
    genrePlaylistCover: function (params) {
        const id = params.id
        return POST(reUrl + `/music/playlist/${id}/contact_image/`, params)
    },
    putPlayList: function (params) {
        const id = params.id
        return PUT(reUrl + `/music/playlist/${id}/`, params)
    },
    fetchLyric: function (params) {
        return POST(reUrl + '/api/fetch_lyric/', params)
    },
    extractLyric: function (params) {
        return POST(reUrl + '/api/extract_lyric/', params)
    },
    translationLyc: function (params) {
        return POST(reUrl + '/api/translation_lyc/', params)
    },
    simplifiedFields: function (params) {
        return POST(reUrl + '/api/simplified_fields/', params)
    },
    splitFilename: function (params) {
        return POST(reUrl + '/api/split_filename/', params)
    },
    replaceText: function (params) {
        return POST(reUrl + '/api/replace_text/', params)
    },
    fixEncoding: function (params) {
        return POST(reUrl + '/api/fix_encoding/', params)
    },
    checkDuplicate: function (params) {
        return POST(reUrl + '/api/check_file_duplicates/', params)
    },
    formatMusic: function (params) {
        return POST(reUrl + '/api/format_music_type/', params)
    },
    splitCueMusic: function (params) {
        return POST(reUrl + '/api/split_cue/', params)
    },
    getRecord: function (params) {
        return GET(reUrl + '/api/record/', params)
    },
    postRecord: function (params) {
        return POST(reUrl + '/api/record/', params)
    },
    getSys: function (params) {
        return GET(reUrl + '/api/sys/', params)
    },
    getSysDict: function (params) {
        return GET(reUrl + '/api/sys/dict/', params)
    },
    getSysInfo: function (params) {
        return GET(reUrl + '/api/sys/info/', params)
    },
    getSysExist: function (params) {
        return GET(reUrl + '/api/sys_exist/', params)
    },
    UpdateSys: function (params) {
        return POST(reUrl + '/api/sys/', params)
    },
    miServiceTest: function (params) {
        return GET(reUrl + '/api/mi_service/test/', params)
    },
    miServiceSearch: function (params) {
        return GET(reUrl + '/api/mi_service/search_device/', params)
    },
    miServicePlay: function (params) {
        return GET(reUrl + '/api/mi_service/play/', params)
    },
    miServicePlayList: function (params) {
        return POST(reUrl + '/api/mi_service/play/', params)
    },
    supervisorControl: function (params) {
        return POST(reUrl + '/api/supervisor/control/', params)
    },
    getMusicCount: function (params) {
        return GET(reUrl + '/music/music_collection/', params)
    },
    getProgressRate: function (params) {
        return POST(reUrl + '/music/music_collection/progress/', params)
    },
    getProgressStatic: function (params) {
        return GET(reUrl + '/music/music_collection/static/', params)
    },
    getListeningRecord: function (params) {
        return GET(reUrl + '/music/listening/record/', params)
    },
    getAuditLog: function (params) {
        return GET(reUrl + '/api/audit_log/', params)
    },
    getAuditLogDetail: function (params) {
        const id = params.id

        return GET(reUrl + `/api/audit_log/${id}/detail_log/`, params)
    },
    stopAuditLogDetail: function (params) {
        const id = params.id

        return POST(reUrl + `/api/audit_log/${id}/stop/`, params)
    },
    stopAuditLogDetailRun: function (params) {
        const id = params.id

        return POST(reUrl + `/api/audit_log/${id}/stop_detail/`, params)
    },
    changeStatusAuditLogDetailRun: function (params) {
        const id = params.id

        return POST(reUrl + `/api/audit_log/${id}/change_status/`, params)
    },
    getRuntimeLog: function (params) {
        return GET(reUrl + '/api/runtime_log/', params)
    },
    getResourceScript: function (params) {
        return GET(reUrl + '/script/tag_resource/', params)
    },
    getActionScript: function (params) {
        return GET(reUrl + '/script/action_script/', params)
    },
    debugTask1: function (params) {
        return GET(reUrl + '/api/task1/', params)
    },
    debugTask2: function (params) {
        return GET(reUrl + '/api/task2/', params)
    },
    batchAutoUpdateArtist: function (params) {
        return POST(reUrl + '/api/batch_auto_update_artist/', params)
    },
    markFolderState: function (params) {
        return POST(reUrl + '/api/update_folder_state/', params)
    },
    WeekRecord: function (params) {
        return POST(reUrl + '/music/record/week_record/', params)
    },
}
